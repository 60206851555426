export const links = [
    { name: 'Home', href: '/', icon: 'house', needUser: false },
    { name: 'Mappa', href: '/map', icon: 'map', needUser: false },
    {
        name: 'Tutte le associazioni',
        href: '/partners',
        icon: 'boxes',
        needUser: false,
    },
    {
        name: 'Tutti gli sponsor',
        href: '/sponsors',
        icon: 'buildings',
        needUser: false,
    },
    {
        name: 'Giochi da Tavolo',
        href: '/games/ttg',
        icon: 'dice-6',
        needUser: false,
    },
    {
        name: 'Giochi di Ruolo',
        href: '/games/rpg',
        icon: 'gem',
        needUser: false,
    },
    {
        name: 'Conferenze',
        href: '/conferences?day=2',
        icon: 'chat-left-quote',
        needUser: false,
    },
    { name: 'Eventi', href: '/events?day=2', icon: 'people', needUser: false },
    {
        name: 'Area Personale',
        href: '/profile',
        icon: 'person-vcard',
        needUser: true,
    },
]

export const eventClasses: { [key: string]: string } = {
    base: 'hidden',
    prenotato: 'border border-[#76B949] text-[#76B949]',
    completo: 'border border-[#CC2428] text-[#CC2428]',
    in_corso: 'border border-[#F9BB1D] text-[#F9BB1D]',
    terminato: 'border border-black',
}

export const eventDateClasses = {
    planned:
        'border border-gray-300 border-t-transparent border-l-transparent rounded-tl-lg bg-white font-regular italic text-black',
    reserved:
        'border border-[#76b949] bg-[#76b949] font-regular italic text-black',
    started:
        'border border-[#f5e836] bg-[#f5e836] font-regular italic text-black',
    ended: 'border border-gray-300 bg-gray-300 font-regular italic text-black',
}

export const eventButtonClasses = {
    active: 'border-[#4fc2eb] bg-[#4fc2eb] font-semibold text-white',
    default: 'border border-black font-regular text-black',
}

export const AuthErrorMessages: { [key: string]: string } = {
    'auth/wrong-password':
        "La password non è valida o l'utente non ha una password.",
    'auth/claims-too-large':
        'Il payload dei claims fornito a setCustomUserClaims() supera la dimensione massima consentita di 1000 byte.',
    'auth/email-already-exists':
        "L'email fornita è già in uso da un altro utente. Ogni utente deve avere un'email univoca.",
    'auth/id-token-expired': 'Il token ID Firebase fornito è scaduto.',
    'auth/id-token-revoked': 'Il token ID Firebase è stato revocato.',
    'auth/insufficient-permission':
        "Le credenziali utilizzate per inizializzare l'SDK Admin non hanno permessi sufficienti per accedere alla risorsa richiesta. Consulta la documentazione per configurare correttamente il progetto Firebase.",
    'auth/invalid-argument':
        "È stato fornito un argomento non valido a un metodo di autenticazione. Il messaggio d'errore dovrebbe contenere ulteriori informazioni.",
    'auth/invalid-claims':
        'Gli attributi dei claims personalizzati forniti a setCustomUserClaims() non sono validi.',
    'auth/invalid-creation-time':
        "L'ora di creazione deve essere una stringa di data UTC valida.",
    'auth/invalid-disabled-field':
        'Il valore fornito per la proprietà utente disabilitato non è valido. Deve essere un valore booleano.',
    'auth/invalid-display-name':
        'Il valore fornito per la proprietà displayName non è valido. Deve essere una stringa non vuota.',
    'auth/invalid-email-verified':
        'Il valore fornito per la proprietà emailVerified non è valido. Deve essere un valore booleano.',
    'auth/invalid-hash-algorithm':
        "L'algoritmo di hashing deve corrispondere a una delle stringhe nell'elenco degli algoritmi supportati.",
    'auth/invalid-hash-block-size':
        'La dimensione del blocco di hash deve essere un numero valido.',
    'auth/invalid-hash-derived-key-length':
        "La lunghezza della chiave derivata dall'hash deve essere un numero valido.",
    'auth/invalid-hash-key':
        'La chiave di hash deve essere un buffer di byte valido.',
    'auth/invalid-hash-memory-cost':
        "Il costo della memoria dell'hash deve essere un numero valido.",
    'auth/invalid-hash-parallelization':
        "La parallelizzazione dell'hash deve essere un numero valido.",
    'auth/invalid-hash-rounds':
        "I cicli dell'hash devono essere un numero valido.",
    'auth/invalid-hash-salt-separator':
        "Il campo del separatore del sale dell'algoritmo di hashing deve essere un buffer di byte valido.",
    'auth/invalid-id-token':
        'Il token ID fornito non è un token ID Firebase valido.',
    'auth/invalid-last-sign-in-time':
        "L'ultima ora di accesso deve essere una stringa di data UTC valida.",
    'auth/invalid-page-token':
        'Il token di pagina successivo fornito in listUsers() non è valido. Deve essere una stringa non vuota valida.',
    'auth/invalid-password':
        'Il valore fornito per la proprietà password non è valido. Deve essere una stringa con almeno sei caratteri.',
    'auth/invalid-password-hash':
        "L'hash della password deve essere un buffer di byte valido.",
    'auth/invalid-password-salt':
        'Il sale della password deve essere un buffer di byte valido.',
    'auth/invalid-photo-url':
        'Il valore fornito per la proprietà photoURL non è valido. Deve essere un URL valido.',
    'auth/invalid-provider-data':
        'Il providerData deve essere un array valido di oggetti UserInfo.',
    'auth/invalid-oauth-responsetype':
        'Solo esattamente un OAuth responseType deve essere impostato su true.',
    'auth/invalid-session-cookie-duration':
        'La durata del cookie di sessione deve essere un numero valido in millisecondi tra 5 minuti e 2 settimane.',
    'auth/invalid-uid':
        "L'UID fornito deve essere una stringa non vuota con al massimo 128 caratteri.",
    'auth/invalid-user-import': 'Il record utente da importare non è valido.',
    'auth/maximum-user-count-exceeded':
        'È stato superato il numero massimo consentito di utenti da importare.',
    'auth/missing-hash-algorithm':
        "L'importazione di utenti con hash della password richiede che venga fornito l'algoritmo di hashing e i relativi parametri.",
    'auth/missing-uid':
        "È necessario un identificatore UID per l'operazione corrente.",
    'auth/missing-oauth-client-secret':
        'Il segreto del client OAuth è necessario per abilitare il flusso di codice OIDC.',
    'auth/phone-number-already-exists':
        'Il numero di telefono fornito è già in uso da un altro utente. Ogni utente deve avere un numero di telefono univoco.',
    'auth/project-not-found':
        "Non è stato trovato alcun progetto Firebase per la credenziale utilizzata per inizializzare l'SDK Admin.",
    'auth/reserved-claims':
        'Uno o più claims personalizzati forniti a setCustomUserClaims() sono riservati. Ad esempio, i claims specifici di OIDC come (sub, iat, iss, exp, aud, auth_time, ecc.) non devono essere usati come chiavi per i claims personalizzati.',
    'auth/session-cookie-expired':
        'Il cookie di sessione Firebase fornito è scaduto.',
    'auth/session-cookie-revoked':
        'Il cookie di sessione Firebase è stato revocato.',
    'auth/uid-already-exists':
        "L'UID fornito è già in uso da un altro utente. Ogni utente deve avere un UID univoco.",
    'auth/admin-restricted-operation':
        'Questa operazione è riservata solo agli amministratori.',
    'auth/app-not-authorized':
        'Questa app, identificata dal dominio in cui è ospitata, non è autorizzata a utilizzare Firebase Authentication con la chiave API fornita.',
    'auth/app-not-installed':
        "L'app mobile richiesta corrispondente all'identificatore fornito non è installata su questo dispositivo.",
    'auth/captcha-check-failed':
        "Il token di risposta reCAPTCHA fornito non è valido, è scaduto, è già stato utilizzato o il dominio associato non corrisponde all'elenco dei domini autorizzati.",
    'auth/code-expired':
        'Il codice SMS è scaduto. Si prega di inviare nuovamente il codice di verifica.',
    'auth/cordova-not-ready': 'Il framework Cordova non è pronto.',
    'auth/cors-unsupported': 'Questo browser non è supportato.',
    'auth/credential-already-in-use':
        'Questa credenziale è già associata a un altro account utente.',
    'auth/custom-token-mismatch':
        'Il token personalizzato corrisponde a un pubblico diverso.',
    'auth/requires-recent-login':
        "Questa operazione è sensibile e richiede un'autenticazione recente. Effettua nuovamente l'accesso prima di riprovare.",
    'auth/dependent-sdk-initialized-before-auth':
        'Un altro SDK Firebase è stato inizializzato e sta tentando di utilizzare Auth prima che Auth sia inizializzato.',
    'auth/dynamic-link-not-activated':
        'Si prega di attivare i Dynamic Links nella Console Firebase e accettare i termini e le condizioni.',
    'auth/email-change-needs-verification':
        "Gli utenti multi-fattore devono avere sempre un'email verificata.",
    'auth/email-already-in-use':
        "L'indirizzo email è già in uso da un altro account.",
    'auth/emulator-config-failed':
        "L'istanza Auth è già stata utilizzata per effettuare una chiamata di rete. Auth non può più essere configurato per utilizzare l'emulatore.",
    'auth/expired-action-code': "Il codice d'azione è scaduto.",
    'auth/cancelled-popup-request':
        "Questa operazione è stata annullata a causa dell'apertura di un popup in conflitto.",
    'auth/internal-error':
        'Si è verificato un errore interno di autenticazione.',
    'auth/invalid-app-credential':
        'La richiesta di verifica del telefono contiene un verificatore di applicazione non valido.',
    'auth/invalid-app-id':
        "L'identificatore dell'app mobile non è registrato per il progetto corrente.",
    'auth/invalid-user-token':
        "La credenziale dell'utente non è valida per questo progetto.",
    'auth/invalid-auth-event':
        'Si è verificato un errore interno di autenticazione.',
    'auth/invalid-verification-code':
        'Il codice di verifica SMS utilizzato per creare la credenziale di autenticazione del telefono non è valido.',
    'auth/invalid-continue-uri':
        "L'URL di continuazione fornito nella richiesta non è valido.",
    'auth/invalid-cordova-configuration':
        "I seguenti plugin Cordova devono essere installati per abilitare l'accesso tramite OAuth.",
    'auth/invalid-custom-token':
        'Il formato del token personalizzato non è corretto.',
    'auth/invalid-dynamic-link-domain':
        'Il dominio del link dinamico fornito non è configurato o autorizzato per il progetto corrente.',
    'auth/invalid-email': "L'indirizzo email è formattato in modo errato.",
    'auth/invalid-emulator-scheme':
        "L'URL dell'emulatore deve iniziare con uno schema valido (http:// o https://).",
    'auth/invalid-api-key':
        'La tua chiave API non è valida, controlla di averla copiata correttamente.',
    'auth/invalid-cert-hash':
        "L'hash del certificato SHA-1 fornito non è valido.",
    'auth/invalid-credential':
        'Le credenziali di autenticazione fornite sono incorrette.',
    'auth/invalid-message-payload':
        'Il modello di email corrispondente a questa azione contiene caratteri non validi nel suo messaggio.',
    'auth/invalid-multi-factor-session':
        'La richiesta non contiene una prova valida del successo del primo fattore di autenticazione.',
    'auth/invalid-oauth-provider':
        'EmailAuthProvider non è supportato per questa operazione.',
    'auth/invalid-oauth-client-id':
        "L'ID client OAuth fornito non è valido o non corrisponde alla chiave API specificata.",
    'auth/unauthorized-domain':
        'Questo dominio non è autorizzato per le operazioni OAuth per il tuo progetto Firebase.',
    'auth/invalid-action-code': "Il codice d'azione non è valido.",
    'auth/invalid-persistence-type':
        'Il tipo di persistenza specificato non è valido. Può essere solo local, session o none.',
    'auth/invalid-phone-number':
        'Il formato del numero di telefono fornito non è corretto.',
    'auth/invalid-provider-id': "L'ID del provider specificato non è valido.",
    'auth/invalid-recipient-email':
        "L'email corrispondente a questa azione non è stata inviata in quanto l'indirizzo email del destinatario fornito non è valido.",
    'auth/invalid-sender':
        "Il modello di email corrispondente a questa azione contiene un'email o un nome del mittente non valido.",
    'auth/invalid-verification-id':
        "L'ID di verifica utilizzato per creare la credenziale di autenticazione del telefono non è valido.",
    'auth/invalid-tenant-id': "L'ID tenant dell'istanza di Auth non è valido.",
    'auth/missing-android-pkg-name':
        "Deve essere fornito un nome di pacchetto Android se è richiesta l'installazione dell'app Android.",
    'auth/auth-domain-config-required':
        'Assicurati di includere authDomain quando chiami firebase.initializeApp(), seguendo le istruzioni nella console di Firebase.',
    'auth/missing-app-credential':
        "La richiesta di verifica del telefono manca di una dichiarazione di verifica dell'applicazione. Deve essere fornito un token di risposta reCAPTCHA.",
    'auth/missing-verification-code':
        'La credenziale di autenticazione del telefono è stata creata con un codice di verifica SMS vuoto.',
    'auth/missing-continue-uri':
        'Nella richiesta deve essere fornito un URL di continuazione.',
    'auth/missing-iframe-start':
        'Si è verificato un errore interno di autenticazione.',
    'auth/missing-ios-bundle-id':
        'Deve essere fornito un ID bundle iOS se viene fornito un ID App Store.',
    'auth/missing-or-invalid-nonce':
        "La richiesta non contiene un nonce valido. Questo può accadere se l'hash SHA-256 del nonce fornito non corrisponde al nonce nell'ID token.",
    'auth/missing-multi-factor-info':
        'Non è stato fornito un identificatore del secondo fattore.',
    'auth/missing-multi-factor-session':
        'La richiesta manca della prova del successo del primo fattore di accesso.',
    'auth/missing-phone-number':
        'Per inviare i codici di verifica, fornisci un numero di telefono per il destinatario.',
    'auth/missing-verification-id':
        'La credenziale di autenticazione del telefono è stata creata con un ID di verifica vuoto.',
    'auth/app-deleted': 'Questa istanza di FirebaseApp è stata eliminata.',
    'auth/multi-factor-info-not-found':
        "L'utente non ha un secondo fattore che corrisponda all'identificatore fornito.",
    'auth/multi-factor-auth-required':
        "È richiesta la prova della proprietà di un secondo fattore per completare l'accesso.",
    'auth/account-exists-with-different-credential':
        'Un account esiste già con lo stesso indirizzo email ma credenziali di accesso diverse. Accedi utilizzando un provider associato a questo indirizzo email.',
    'auth/network-request-failed':
        'Si è verificato un errore di rete (ad esempio, timeout, connessione interrotta o host non raggiungibile).',
    'auth/no-auth-event':
        'Si è verificato un errore interno di autenticazione.',
    'auth/no-such-provider':
        "L'utente non è collegato a un account con il provider specificato.",
    'auth/null-user':
        "È stato fornito un oggetto utente nullo come argomento per un'operazione che richiede un utente non nullo.",
    'auth/operation-not-allowed':
        'Il provider di accesso specificato è disabilitato per questo progetto Firebase. Abilitalo nella console Firebase, nella scheda metodo di accesso della sezione Auth.',
    'auth/operation-not-supported-in-this-environment':
        "Questa operazione non è supportata nell'ambiente in cui è in esecuzione l'applicazione. 'location.protocol' deve essere http, https o chrome-extension e l'archiviazione web deve essere abilitata.",
    'auth/popup-blocked':
        'Impossibile stabilire una connessione con il popup. Potrebbe essere stato bloccato dal browser.',
    'auth/popup-closed-by-user':
        "Il popup è stato chiuso dall'utente prima di completare l'operazione.",
    'auth/provider-already-linked':
        "L'utente può essere collegato solo a un'identità per il provider specificato.",
    'auth/quota-exceeded':
        'È stata superata la quota del progetto per questa operazione.',
    'auth/redirect-cancelled-by-user':
        "L'operazione di reindirizzamento è stata annullata dall'utente prima del completamento.",
    'auth/redirect-operation-pending':
        "È già in corso un'operazione di accesso tramite reindirizzamento.",
    'auth/rejected-credential':
        'La richiesta contiene credenziali malformate o non corrispondenti.',
    'auth/second-factor-already-in-use':
        'Il secondo fattore è già registrato su questo account.',
    'auth/maximum-second-factor-count-exceeded':
        'È stato superato il numero massimo consentito di secondi fattori su un utente.',
    'auth/tenant-id-mismatch':
        "L'ID tenant fornito non corrisponde all'ID tenant dell'istanza di Auth.",
    'auth/timeout': "L'operazione ha superato il tempo limite.",
    'auth/user-token-expired':
        "La credenziale dell'utente non è più valida. L'utente deve effettuare nuovamente l'accesso.",
    'auth/too-many-requests':
        'Abbiamo bloccato tutte le richieste da questo dispositivo a causa di attività insolite. Riprova più tardi.',
    'auth/unauthorized-continue-uri':
        "Il dominio dell'URL di continuazione non è nella lista consentita. Per favore, autorizza il dominio nella console Firebase.",
    'auth/unsupported-first-factor':
        "La registrazione di un secondo fattore o l'accesso con un account multi-fattore richiede l'accesso con un primo fattore supportato.",
    'auth/unsupported-persistence-type':
        "L'ambiente corrente non supporta il tipo di persistenza specificato.",
    'auth/unsupported-tenant-operation':
        'Questa operazione non è supportata in un contesto multi-tenant.',
    'auth/unverified-email': "L'operazione richiede un'email verificata.",
    'auth/user-cancelled':
        "L'utente non ha concesso all'applicazione le autorizzazioni richieste.",
    'auth/user-not-found':
        "Non esiste un record utente corrispondente a questo identificatore. L'utente potrebbe essere stato eliminato.",
    'auth/user-disabled':
        "L'account utente è stato disabilitato da un amministratore.",
    'auth/user-mismatch':
        "Le credenziali fornite non corrispondono all'utente precedentemente connesso.",
    'auth/weak-password': 'La password deve essere di almeno 6 caratteri.',
    'auth/web-storage-unsupported':
        'Questo browser non è supportato o i cookie di terze parti e i dati potrebbero essere disabilitati.',
    'auth/already-initialized':
        "initializeAuth() è già stato chiamato con opzioni diverse. Per evitare questo errore, chiama initializeAuth() con le stesse opzioni della chiamata originale o chiama getAuth() per restituire l'istanza già inizializzata.",
}
