import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc'
import { AuthErrorMessages } from './models'

export const pathnameProps = (pathname: string) => {
    switch (true) {
        case pathname === '/map': 
            return { title: 'Mappa', back: true }
        case pathname === '/games/rpg':
            return { title: 'Giochi di Ruolo', back: false }
        case pathname === '/games/ttg':
            return { title: 'Giochi da Tavolo', back: false }
        case pathname === '/events':
            return { title: 'Eventi', back: false }
        case pathname === '/partners':
            return { title: 'Associazioni', back: false }
        case pathname === '/sponsors':
            return { title: 'Sponsors', back: false }
        case pathname === '/conferences':
            return { title: 'Conferenze', back: false }
        case pathname.includes('/games/'):
            return { title: 'Gioco', back: true }
        case pathname.includes('/events/'):
            return { title: 'Evento', back: true }
        case pathname.includes('/partners/'):
            return { title: 'Associazione', back: true }
        case pathname.includes('/sponsors/'):
            return { title: 'Sponsor', back: true }
        case pathname.includes('/conferences/'):
            return { title: 'Conferenza', back: true }
        case pathname.includes('/profile/'):
            return { title: 'Area personale', back: true }
        case pathname.includes('/search'):
            return { title: 'Ricerca', back: true }
        default:
            return { title: 'Home', back: false }
    }
}

export const getEventStatus = (startDate: string, endDate: string) => {
    dayjs.extend(isBetween)
    dayjs.extend(isSameOrAfter)
    dayjs.extend(utc)
    const currentDate = dayjs().add(2, 'hours')
    const start = dayjs(startDate)
    const end = dayjs(endDate)
    let status = 'base'

    if (currentDate.isBetween('2024-09-07', '2024-09-08', 'day', '[]')) {
        if (currentDate.isBetween(start, end, 'minutes', '[)'))
            status = 'in_corso'
        else if (currentDate.isSameOrAfter(endDate, 'minutes'))
            status = 'terminato'
    }

    return status
}

export function getTypePath(type: string) {
    switch (type) {
        case 'Giochi da Tavolo':
            return 'games'
        case 'Giochi di Ruolo':
            return 'games'
        case 'Associazione':
            return 'partners'
        case 'Sponsor':
            return 'sponsors'
        default:
            return 'events'
    }
}

export function getEventDate(dayNum: number) {
    switch (dayNum) {
        case 1:
            return '2024-09-07'
        case 2:
            return '2024-09-08'
        default:
            return ''
    }
}

export function dateISOStringToDay(date: string) {
    const newDate = new Date(date)
    return (
        newDate.toLocaleDateString('it-IT', { weekday: 'long' }) +
        ' ' +
        newDate.getDate()
    )
}

export function getAuthErrorMessage(errorCode: string): string {
    return AuthErrorMessages[errorCode] || "Si è verificato un errore sconosciuto.";
} 